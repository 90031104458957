/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useUserAuthStore } from 'src/store/userAuth/userStore';

const UserInfo = ({ open }: { open?: boolean }) => {
  const dbUser = useUserAuthStore((state) => state.dbUser);

  return (
    <>
      <ListItemAvatar sx={{ minWidth: 38 }}>
        <Tooltip
          arrow
          disableHoverListener={open}
          placement="right"
          title={`Logged in as ${dbUser?.firstName} ${dbUser?.lastName}`}
        >
          <Avatar alt="Profile Picture" sx={{ width: 28, height: 28 }} />
        </Tooltip>
      </ListItemAvatar>
      {dbUser?.firstName && dbUser?.lastName && dbUser?.role.name && (
        <ListItemText
          primary={dbUser?.firstName + ' ' + dbUser?.lastName}
          secondary={dbUser?.role.name
            .replaceAll('_', ' ')
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase())} // TODO: Refactor this to a helper function
          sx={{ opacity: open ? 1 : 0 }}
          primaryTypographyProps={{
            fontWeight: 500,
            fontSize: '11px',
          }}
          secondaryTypographyProps={{
            fontSize: '10px',
          }}
        />
      )}
    </>
  );
};

export default UserInfo;
