import { NavigateNext } from '@mui/icons-material';
import { Link, Breadcrumbs as MuiBreadCrumb, Typography, capitalize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumbContext } from 'src/context/BreadcrumbContext';
import routes from 'src/router/router';
import { useUserAuthStore } from 'src/store/userAuth/userStore';
import useBreadcrumbs, { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import LoadingText from '../LoadingText/LoadingText';

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);
  const dbUser = useUserAuthStore((state) => state.dbUser);
  const { breadcrumbNames } = useBreadcrumbContext();

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {
    event.preventDefault();
    navigate(path, { replace: true });
  };

  const isLast = (index: number) => index === breadcrumbs.length - 1;

  const getBreadcrumbLabel = (
    breadcrumb: React.ReactNode,
    match: BreadcrumbMatch<string>
  ): string => {
    const pathSegments = match.pathname.split('/');
    const matchParamsValues = Object.values(match.params);
    const currentSegment = pathSegments[pathSegments.length - 1];
    const label =
      breadcrumbNames[currentSegment] || capitalize(currentSegment.replaceAll('-', ' '));
    return matchParamsValues.includes(label) ? 'loading' : label;
  };

  const shouldShowClientsBreadcrumb = (label: string, role: string) => {
    return label !== 'Clients' || role === 'LS_ADMIN' || role === 'LS_USER';
  };

  return (
    <MuiBreadCrumb
      // maxItems={4}
      separator={<NavigateNext fontSize="small" color="primary" />}
      aria-label="breadcrumb"
      sx={{
        // fontWeight: 700,
        '& .MuiBreadcrumbs-separator': {
          margin: 1,
        },
      }}
    >
      {breadcrumbs.map(({ match, breadcrumb }, index: number) => {
        const breadcrumbLabel = getBreadcrumbLabel(breadcrumb, match);

        if (breadcrumbLabel === 'loading') {
          return <LoadingText loadingText="" key={index} />;
        }
        if (dbUser && !shouldShowClientsBreadcrumb(breadcrumbLabel, dbUser.role.name)) {
          return null; // Skip rendering this breadcrumb if it's "Clients" and the role doesn't match
        }

        return match.pattern.path === '/*' ? (
          <Typography sx={{ fontWeight: 500 }} key={index}>
            {getBreadcrumbLabel(breadcrumb, match)}
          </Typography>
        ) : !isLast(index) ? (
          <Link
            // color="inherit"
            key={index}
            sx={{ cursor: 'pointer', fontWeight: 500 }}
            onClick={(event) => handleClick(event, match.pathname)}
          >
            {getBreadcrumbLabel(breadcrumb, match)}
          </Link>
        ) : (
          <Typography sx={{ fontWeight: 500 }} key={index}>
            {getBreadcrumbLabel(breadcrumb, match)}
          </Typography>
        );
      })}
    </MuiBreadCrumb>
  );
}

export default Breadcrumbs;
