import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface BreadcrumbNames {
  [key: string]: string;
}

interface BreadcrumbContextType {
  breadcrumbNames: BreadcrumbNames;
  setBreadcrumbNames: Dispatch<SetStateAction<BreadcrumbNames>>;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

interface BreadcrumbProviderProps {
  children: ReactNode;
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({ children }) => {
  const [breadcrumbNames, setBreadcrumbNames] = useState<BreadcrumbNames>({});

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbNames, setBreadcrumbNames }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbContext = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumbContext must be used within a BreadcrumbProvider');
  }
  return context;
};
