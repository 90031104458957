import { TypographyOptions } from '@mui/material/styles/createTypography';

// New fonts imported in the index.html file inside the head tag

// introduce new typography variant to be used for poster text
// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     poster: React.CSSProperties;
//   }
//   interface TypographyVariantsOptions {
//     poster?: React.CSSProperties;
//   }
// }
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     poster: true;
//     h3: false;
//   }
// }

export const typography: TypographyOptions = {
  // htmlFontSize: 16,
  // fontSize: 14,
  // fontWeightLight: 300,
  // fontWeightRegular: 400,
  // fontWeightMedium: 500,
  // fontWeightBold: 700,
  h1: {
    // fontWeight: 800,
    // fontSize: 64,
  },
  h2: {
    // fontWeight: 800,
    // fontSize: 55,
  },
  h3: {
    // fontWeight: 800,
    // fontSize: 40,
    // letterSpacing: 0.4,
  },
  h4: {
    // fontWeight: 800,
    // fontSize: 32,
    // letterSpacing: 0.32,
  },
  h5: {
    // fontWeight: 800,
    // fontSize: 24,
    // letterSpacing: 0.24,
  },
  h6: {
    // fontSize: 20,
    // letterSpacing: 0.2,
    // fontWeight: 800,
  },
  body1: {
    // letterSpacing: 0.14,
    // fontSize: 14,
  },
  body2: {
    // letterSpacing: 0.12,
    // fontSize: 12,
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  caption: {
    // fontSize: 13,
    // textTransform: 'uppercase',
  },
  subtitle1: {
    // fontSize: 14,
  },
  subtitle2: {
    // fontWeight: 400,
    // fontSize: 15,
  },
  overline: {
    // fontSize: 14,
    // fontWeight: 400,
    // textTransform: 'uppercase',
  },
};
