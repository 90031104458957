/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const rtkQueryMiddleware: Middleware = (_api) => (next) => (action: any) => {
  if (isRejectedWithValue(action)) {
    handleRejectedStatus(action);
    if (action.payload.httpStatusCode) {
      action.status = action.payload.httpStatusCode;
    } else {
      // toast.info('Th api has no status code for the error');
    }
  } else {
    if (action?.meta?.arg?.type === 'mutation') {
      // handleMutationAction(action);
    }
    // transform the response to include the status code and the value
    if (action.type.endsWith('/fulfilled')) {
      if (action?.meta?.requestStatus === 'fulfilled') {
        // handle the fulfilled action
      }
    }
  }
  return next(action);
};

const handleMutationAction = (action: any) => {
  // Handle successful action
  if (action.type.endsWith('/fulfilled')) {
    toast.dismiss(); // Dismiss any existing toast
    toast.success(action?.payload?.data?.message ?? 'Success!', { hideProgressBar: true }); // Show success message
  } else if (action.type.endsWith('/pending')) {
    // Handle pending action
    toast.info(`Requesting ${action?.meta?.arg?.endpointName}...`, {
      // Show pending message
      hideProgressBar: true,
    });
  }
};

const handleRejectedStatus = (action: any) => {
  toast.dismiss();
  const status = action?.payload?.data?.httpStatusCode || action?.payload?.status;
  const message = action?.payload?.data?.errorMessage;
  switch (status) {
    case 401:
      toast.error(message ?? 'Your session has expired. Redirecting to the login page.', {
        autoClose: 10000,
      });
      break;
    case 403:
      toast.error(message ?? 'User not authorised to perform this action.');
      break;
    case 500:
      toast.error(message ?? 'Internal server error.');
      break;
    case 404:
      toast.error(message ?? 'Resource not found.');
      break;
    case 400:
      toast.error(message ?? 'An error has occurred', { autoClose: 10000 });
      break;
    default:
      toast.error(message ?? 'An error has occurred.', { autoClose: 10000 });
      break;
  }
};
