import {
  GetAllBuildingsData,
  GetAllBuildingsResponse,
  GetAllBuildingsTableData,
  GetAllBuildingsTableResponse,
} from 'src/apiClient/types.gen';
import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

const buildingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## GET ALL Buildings ###################################
    getAllBuildings: builder.query<GetAllBuildingsResponse, GetAllBuildingsData['query']>({
      query: (query) => ({
        url: ENDPOINT.BUILDINGS,
        method: 'GET',
        params: query,
      }),
    }),
    getAllBuildingsTable: builder.query<
      GetAllBuildingsTableResponse,
      GetAllBuildingsTableData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.BUILDINGS}/buildingTable`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetAllBuildingsTableResponse) => response,
    }),
  }),
});

export const {
  useGetAllBuildingsQuery,
  useLazyGetAllBuildingsQuery,
  useGetAllBuildingsTableQuery,
  useLazyGetAllBuildingsTableQuery,
} = buildingsApi;
