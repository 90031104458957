import { type RouteObject } from 'react-router-dom';
import { Can } from 'src/context/canContext';

export const useAbilityRoutes = (routes: RouteObject[], instruction: string): RouteObject[] => {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        children: useAbilityRoutes(route.children, instruction),
      };
    }

    return {
      ...route,
      element: (
        <Can I={instruction} a={route.path ?? 'no'}>
          {route.element}
        </Can>
      ),
    };
  });
};
