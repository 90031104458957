import { createAsyncThunk } from '@reduxjs/toolkit';

export const resetUser = createAsyncThunk('user/resetUser', async (_, { rejectWithValue }) => {
  try {
    return true;
  } catch (error: unknown) {
    const err = error as Error;
    return rejectWithValue(err.message);
  }
});
