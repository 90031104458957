import { Suspense, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SuspenseLoader from 'src/components/suspenseLoader';
import { useAuth } from 'src/features/authentication';
import { useLazyGetUserMeQuery } from 'src/services/restApi/queries/users.service';
import { useUserAuthStore } from 'src/store/userAuth/userStore';

export default function AuthGuard({ children }: { children: JSX.Element }) {
  try {
    const location = useLocation();
    const { loading, error, user, isAuthenticated, getTokens } = useAuth();
    const navigate = useNavigate();

    const userActions = useUserAuthStore((state) => state);

    const [getDbUserRole] = useLazyGetUserMeQuery();

    useEffect(() => {
      const prep = async () => {
        try {
          const tokens = await getTokens();
          userActions.setUser(user);
          userActions.setUserStatus('authenticated');
          userActions.setAccessToken(tokens?.accessToken ?? '');
          userActions.setRefreshToken(tokens?.refreshToken ?? '');
          const dbUser = await getDbUserRole();
          userActions.setUserRole(dbUser.data?.value?.role.name ?? 'guest');
          if (dbUser.data?.value && dbUser.data.isSuccess) {
            userActions.setDbUser(dbUser.data.value);
          }
        } catch (error) {
          toast.error(
            'There has been an error getting authentication details. Please try logging in'
          );
          navigate('/auth/login');
        }
      };
      if (!loading && user) {
        prep();
      }

      if (error) {
        toast.error(
          'There has been an error getting authentication details. Please try logging in'
        );
        navigate('/auth/login');
      }
    }, [user, loading, error]);

    if (loading || loading) {
      return (
        <Suspense fallback={<SuspenseLoader />}>
          <SuspenseLoader />
        </Suspense>
      );
    }

    if (!isAuthenticated && !user && !loading) {
      // Redirect users to the login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      if (!userActions || !userActions.accessToken || !userActions.refreshToken) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />;
      }
    }

    // if (!loading && !emailVerified) {
    //   return <Navigate to="/status/unverified" state={{ from: location }} replace />;
    // }
  } catch (error) {
    //Route to page if anything failed here
    return <Navigate to="/status/500" replace />;
  }

  return children;
}
