import { Box, keyframes, styled } from '@mui/material';
import React from 'react';

// Define the bounce animation using keyframes
const bounce = keyframes`
0%, 60%, 80%, 100% { transform: translateY(4px); }
40% { transform: translateY(0px); }
`;

// Create a styled component for the Dot
const Dot = styled('div')<{ delay: string }>(({ theme, delay }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: 4,
  height: 4,
  margin: '0 3px',
  animation: `${bounce} 0.8s ${delay} infinite ease-in-out`,
}));

type LoadingTextProps = {
  loadingText: string;
};

const LoadingText: React.FC<LoadingTextProps> = ({ loadingText }) => {
  return (
    <Box display="flex" alignItems="center">
      {loadingText}
      <Box display="flex">
        <Dot delay="0s" />
        <Dot delay=".2s" />
        <Dot delay=".4s" />
      </Box>
    </Box>
  );
};

export default LoadingText;
