import { CloseTwoTone, MenuTwoTone } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'src/components/breadcrumbs/BreadCrumbs';
import HiddenWrapper from 'src/components/componentWrappers/HiddenWrapper';
import RefreshChip from 'src/components/refreshChip/RefreshChip';
import appConfig from 'src/config/appConfig';
import { AppBarProps } from '../..';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${theme.sidebar.closedWidth})`,
  ...(open && {
    marginLeft: theme.sidebar.width,
    width: `calc(100% - ${theme.sidebar.width})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: theme.header.height,
  [theme.breakpoints.down(appConfig.breakMobileView)]: {
    width: '100%',
  },
  padding: '0px 32px',
  boxShadow: 'none',
  backgroundColor: theme.header.background,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
}));

const HamburgerIconButton = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(appConfig.breakMobileView)]: {
    display: 'none',
  },
  marginRight: 10,
  color: theme.palette.primary.main,
}));

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function HeaderLayout({ open, setOpen }: Props) {
  const { claimNoId, buildingId, clientId } = useParams<{
    claimNoId: string;
    buildingId: string;
    clientId: string;
  }>();
  return (
    <StyledAppBar position="fixed" open={open} id="header-layout-app-bar">
      <HamburgerIconButton onClick={() => setOpen(!open)}>
        {!open ? (
          <MenuTwoTone fontSize="medium" id="header-layout-hamburger-icon" />
        ) : (
          <CloseTwoTone fontSize="medium" id="header-layout-hamburger-close-icon" />
        )}
      </HamburgerIconButton>
      <HiddenWrapper desktopOnly>
        <Breadcrumbs />
        <Box></Box>

        <Box>
          {clientId && (
            <RefreshChip clientId={clientId} buildingId={buildingId} claimId={claimNoId} />
          )}
        </Box>
      </HiddenWrapper>
    </StyledAppBar>
  );
}
