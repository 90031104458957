import { ResultWithValueUserVm as ResultsWithValueUser, UserUpdateIm } from 'src/apiClient';
import { ENDPOINT, TAG_ID } from '../config/apiTags';
import { baseApi } from './baseApi.service';

const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserMe: builder.query<ResultsWithValueUser, void>({
      query: () => ({
        url: `${ENDPOINT.USERS}/me`,
        method: 'GET',
      }),
      providesTags: (result) => [
        { type: ENDPOINT.USERS, id: result?.value?.id ?? TAG_ID.UNAUTHORIZED },
      ],
    }),
    updateUser: builder.mutation<ResultsWithValueUser, UserUpdateIm & { userId: number }>({
      query: ({ userId, ...rest }) => ({
        url: `${ENDPOINT.USERS}/${userId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: [{ type: ENDPOINT.USERS, id: TAG_ID.UNAUTHORIZED }],
    }),
  }),
});

export const { useGetUserMeQuery, useLazyGetUserMeQuery, useUpdateUserMutation } = usersApi;
