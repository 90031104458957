const pages = {
  auth: {
    root: 'auth',
    login: {
      name: 'login',
      path: '/auth/login',
    },
    register: {
      name: 'register',
      path: '/auth/register',
    },
  },
  home: {
    name: 'home',
    path: '/home',
  },
  page1: {
    name: 'page1',
    path: '/page1',
  },
  client: {
    name: 'clients',
    path: '/clients',
    buildings: {
      path: '/clients/:clientId/buildings',
      name: 'buildings',
      claims: {
        name: 'claims',
        path: '/clients/:clientId/buildings/:buildingId/claims',
        claimNo: {
          name: 'claimNo',
          path: '/clients/:clientId/buildings/:buildingId/claims/:claimNoId/claimNo',
        },
      },
    },
  },
  status: {
    root: 'status',
    unverified: {
      name: 'unverified',
      path: '/status/unverified',
    },
    statusComingSoon: {
      name: 'coming-soon',
      path: '/status/coming-soon',
    },
    statusMaintenance: {
      name: 'maintenance',
      path: '/status/maintenance',
    },
    status404: {
      name: '404',
      path: '/status/404',
    },
    status500: {
      name: '500',
      path: '/status/500',
    },
    statusSuccess: {
      name: 'success',
      path: '/status/success',
    },
    statusFailure: {
      name: 'failure',
      path: '/status/failure',
    },
    statusCancel: {
      name: 'cancel',
      path: '/status/cancel',
    },
  },
};

export default pages;
