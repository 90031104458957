// This is the endpoints that are used in the application
// It is used in the query/mutation and will be fixed to the base url
// i.e {BASE_URL}/users
export enum ENDPOINT {
  USERS = '/users',
  CLIENTS = '/clients',
  BUILDINGS = '/buildings',
  CLAIMS = '/claims',
  TRANSACTIONS = '/transactions',
  BALANCES = '/balances',
  STORAGE = '/storage',
}

/**
 * The Tag ID is used in the query/mutation to provide tags for the query/mutation in the case that the query/mutation is successful
 */

export enum TAG_ID {
  PARTIAL_LIST = 'PARTIAL-LIST',
  LIST = 'LIST',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export const TAG_TYPES: readonly string[] = Object.values(ENDPOINT);
