import {
  GetDownloadSignedUrlsData,
  GetDownloadSignedUrlsResponse,
  GetUploadSignedUrlData,
  GetUploadSignedUrlResponse,
} from 'src/apiClient';
import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

const storageApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSignedUrlsForUpload: builder.mutation<
      GetUploadSignedUrlResponse,
      GetUploadSignedUrlData['body']
    >({
      query: (body) => ({
        url: `${ENDPOINT.STORAGE}/upload-signed-url`,
        method: 'POST',
        body,
      }),
    }),
    getSignedUrlsForDownload: builder.mutation<
      GetDownloadSignedUrlsResponse,
      GetDownloadSignedUrlsData['body']
    >({
      query: (body) => ({
        url: `${ENDPOINT.STORAGE}/download-signed-urls`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetSignedUrlsForUploadMutation, useGetSignedUrlsForDownloadMutation } =
  storageApi;
