import { alpha, PaletteOptions } from '@mui/material';
/**
 * Defines the color schemes for the light mode and dark mode.
 * Generate tokens using augmentColor utility for new colors (teritary)
 */
const lightModeColors = {
  primary: '#00A0DF',
  secondary: '#000000',
  success: '#0E8345',
  warning: '#FF8F2D',
  error: '#DE1135',
  info: '#E5E6FF',
  black: '#000000',
  white: '#FFFFFF',
  trueWhite: '#FFFFFF',
  uiSurface: '#F8FAFB',
  backGround: '#F9F9FB;',
};

declare module '@mui/material/styles' {
  interface Palette {
    shadows: {
      success: string;
      error: string;
      info: string;
      primary: string;
      warning: string;
      card: string;
      cardSm: string;
      cardLg: string;
    };
  }

  interface PaletteOptions {
    shadows?: {
      success: string;
      error: string;
      info: string;
      primary: string;
      warning: string;
      card: string;
      cardSm: string;
      cardLg: string;
    };
  }
}

export const lightModePalette: PaletteOptions = {
  mode: 'light',
  common: {
    black: lightModeColors.black,
    white: lightModeColors.white,
  },

  primary: {
    main: lightModeColors.primary,
  },
  secondary: {
    main: lightModeColors.secondary,
  },
  error: {
    main: lightModeColors.error,
  },
  success: {
    main: lightModeColors.success,
  },
  info: {
    main: lightModeColors.info,
  },
  warning: {
    main: lightModeColors.warning,
  },
  text: {
    // primary: lightModeColors.primary,
    // secondary: lightModeColors.primary,
    // disabled: lightModeColors.primary,
  },
  background: {
    paper: lightModeColors.white,
    default: lightModeColors.uiSurface,
  },
  // divider: lighten(lightModeColors.black, 0.1),
  action: {
    active: lightModeColors.black,
    // hover:
    hoverOpacity: 0.1,
    // selected: lighten(lightModeColors.black, 0.8),
    selectedOpacity: 0.1,
    disabled: alpha(lightModeColors.black, 0.1),
    // disabledBackground: alpha(lightModeColors.black, 0.9),
    disabledOpacity: 1,
    // focus: lighten(lightModeColors.black, 0.1),
    focusOpacity: 0.05,
    activatedOpacity: 0.12,
  },

  grey: {
    50: '#f3f3f3',
    100: '#e8e8e8',
    200: '#dddddd',
    300: '#bbbbbb',
    400: '#a6a6a6',
    500: '#868686',
    600: '#727272',
    700: '#5e5e5e',
    800: '#4b4b4b',
    900: '#282828',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: {
    light: 0.5,
    dark: 0.5,
  },
  shadows: {
    success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary: '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 2px 4px 0px rgba(47, 49, 146, 0.10)',
    cardSm: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    cardLg:
      '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
  },
};
