import { Shadows } from '@mui/material';

export const shadows: Shadows = [
  'none',
  '0px 1px 6px 0px #E8ECED',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 3px 3px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
  //appbar
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  'none',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
  'none',
];
