import {
  GetLsClientsData,
  GetLsClientsResponse,
  GetLsClientsTableData,
  GetLsClientsTableResponse,
} from 'src/apiClient/types.gen';
import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

const clientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## GET ALL Clients ###################################
    getAllClients: builder.query<GetLsClientsResponse, GetLsClientsData['query']>({
      query: (query) => ({
        url: ENDPOINT.CLIENTS,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetLsClientsResponse) => response,
    }),
    getLsClientsTable: builder.query<GetLsClientsTableResponse, GetLsClientsTableData['query']>({
      query: (query) => ({
        url: `${ENDPOINT.CLIENTS}/clientTable`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetLsClientsTableResponse) => response,
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useLazyGetAllClientsQuery,
  useGetLsClientsTableQuery,
  useLazyGetLsClientsTableQuery,
} = clientsApi;
