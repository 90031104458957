/**
 * This file defines the PureLightTheme, which is a custom light theme for the application.
 * It imports necessary dependencies from the base theme and defines the theme object with various style overrides options.
 * The theme is used to customize the appearance of components in the application.
 * When adding new components, please use the theme configuration options to customize the appearance of the component.
 */

import '@mui/lab/themeAugmentation';
import {
  ThemeOptions,
  buttonClasses,
  checkboxClasses,
  chipClasses,
  createTheme,
  darken,
  lighten,
  switchClasses,
  tabClasses,
} from '@mui/material';
import { Theme, alpha } from '@mui/material/styles';
import { baseThemeOptions } from './foundations/baseThemeOptions';
import { lightModePalette } from './foundations/palette';

let theme = createTheme({
  ...baseThemeOptions,
  palette: lightModePalette,
});

theme = createTheme(theme, {
  sidebar: {
    background: theme.palette.background.paper,
    textColor: theme.palette.text.primary,
    dividerBg: theme.palette.common.white,
    menuItemColor: theme.palette.text.primary,
    menuItemColorActive: theme.palette.primary.main,
    menuItemBg: theme.palette.background.paper,
    menuItemBgActive: theme.palette.background.paper,
    menuItemIconColor: lighten(theme.palette.text.primary, 0.3),
    menuItemIconColorActive: theme.palette.primary.main,
    menuItemHeadingColor: darken(theme.palette.text.primary, 0.3),
    boxShadow: `0px 2px 4px 0px' ${lighten(theme.palette.primary.main, 0.1)}`,
    width: theme.spacing(24.375),
    closedWidth: `calc(${theme.spacing(6)} + 2px)`,
  },
  header: {
    height: theme.spacing(9.5),
    background: theme.palette.background.default,
    textColor: theme.palette.text.primary,
  },
  components: {
    // Blur backdrop on dropdown menu or select component
    MuiBackdrop: {
      defaultProps: {
        // invisible: true,
      },
      styleOverrides: {
        root: {
          '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
          '&.MuiBackdrop-invisible': {
            // backgroundColor: 'transparent',
            // backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    // Add Label on textfields above the input
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          fontWeight: 'bold',
          transform: 'none',
          backgroundColor: 'transparent',
          alignSelf: 'flex-start',
          position: 'relative',
          padding: '4px 0px 0px 0px',
        },

        formControl: {
          // applied to texfield and select components
          marginBottom: 4,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {},
      styleOverrides: {
        icon: {
          '&:not(.Mui-disabled)': {
            color: theme.palette.primary.main,
          },
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
        // color: 'secondary',
      },
      styleOverrides: {
        root: {},
      },
    },

    MuiChip: {
      defaultProps: {
        clickable: true,
      },
      styleOverrides: {
        root: {
          [`&.${chipClasses.clickable}.${chipClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.action.disabled,
              color: theme.palette.common.white,
            },
            '&:not(.Mui-disabled)': {
              '&:hover': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.light,
              },
              '&:active': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            },
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.colorPrimary}`]: {
            '&:not(.Mui-disabled)': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          [`&.${switchClasses.root}`]: {
            width: 54,
            height: 32,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 7,
              transitionDuration: '300ms',
              color: theme.palette.primary.main,
              '&.Mui-checked': {
                transform: 'translateX(20px)',
                color: theme.palette.common.white,
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 18,
              height: 18,
            },
            '& .MuiSwitch-track': {
              border: '1px solid #000',
              borderColor: theme.palette.primary.main,
              borderRadius: 32 / 2,
              backgroundColor: theme.palette.common.white,
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          },
        },
      },
    },

    MuiTab: {
      defaultProps: {
        iconPosition: 'end',
      },
      styleOverrides: {
        root: {
          minHeight: '46px',
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
          border: '2px solid',
          borderBottom: 'none',

          borderColor: alpha(theme.palette.common.black, 0.1),
          [theme.breakpoints.up('md')]: {
            minWidth: 160,
          },
          margin: theme.spacing(0, 0.25),
          '&:not(:first-of-type)': {
            margin: theme.spacing(0, 0.25),
          },
          '&:first-of-type': {
            marginLeft: 0,
          },
          '&:hover': {
            backgroundColor: 'rgba(29, 161, 242, 0.1)',
            color: theme.palette.primary.main,
          },

          [`&.${tabClasses.selected}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '46px',
        },
        indicator: {
          display: 'none',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: `${lighten(theme.palette.common.black, 0.9)} !important`,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {},

        head: {
          fontWeight: '400 !important',
          color: alpha(theme.palette.common.black, 0.8),
          fontSize: '12px',
        },
        body: {
          fontSize: 14,
          color: alpha(theme.palette.common.black, 0.6),
        },
      },
    },

    MuiButton: {
      // borderradius set in basetheme radius
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}`]: {
            padding: '5px 15px',
            border: '1px solid',
            color: theme.palette.common.white,
            '&:not(.Mui-disabled)': {
              borderColor: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.light,
                color: theme.palette.text.primary,
              },
              '&:active': {
                background: theme.palette.common.white,
                color: theme.palette.text.primary,
              },
            },
            '&.Mui-disabled': {
              color: theme.palette.common.white,
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
          },
          [`&.${buttonClasses.outlined}.${buttonClasses.colorSecondary}`]: {
            '&:not(.Mui-disabled)': {
              borderColor: theme.palette.secondary.main,
              '&:hover': {
                background: theme.palette.primary.light,
                color: theme.palette.text.primary,
              },
            },
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
          },
          [`&.${buttonClasses.text}.${buttonClasses.colorSecondary}`]: {
            '&:not(.Mui-disabled)': {
              '&:hover': {
                color: theme.palette.primary.main,
                background: 'transparent',
              },
            },
          },
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {},
    },

    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          width: '100%',
          height: '100%',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1,
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          background: theme.palette.primary.light,
        },
        '#nprogress .spinner-icon': {
          borderTopColor: theme.palette.primary.light,
          borderLeftColor: theme.palette.primary.light,
        },
        '#nprogress .peg': {
          boxShadow:
            '0 0 15px ' + theme.palette.primary.light + ', 0 0 8px' + theme.palette.primary.light,
        },
        ':root': {
          '--swiper-theme-color': theme.palette.primary.main,
        },
        code: {
          background: theme.palette.info.light,
          color: theme.palette.info.dark,
          borderRadius: 4,
          padding: 4,
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0,
          },
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)',
          },
          '100%': {
            transform: 'translate(3%, 3%)',
          },
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
          button: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
  },
} as ThemeOptions) as Theme;

export { theme as PureLightTheme };
