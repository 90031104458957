import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

import {
  GetAllTransactionsData,
  GetAllTransactionsResponse,
  GetLastRequestedSyncTimeData,
  GetLastRequestedSyncTimeResponse,
  TriggerTransactionsLambdaData,
  TriggerTransactionsLambdaResponse,
} from 'src/apiClient';

const transactionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query<GetAllTransactionsResponse, GetAllTransactionsData['query']>({
      query: (query) => ({
        url: `${ENDPOINT.TRANSACTIONS}`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetAllTransactionsResponse) => response,
    }),
    triggerTransactionsLambda: builder.mutation<
      TriggerTransactionsLambdaResponse,
      TriggerTransactionsLambdaData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.TRANSACTIONS}/lambda`,
        method: 'POST',
        params: query,
      }),
      transformResponse: (response: TriggerTransactionsLambdaResponse) => response,
    }),
    getLastRequestedSyncTime: builder.query<
      GetLastRequestedSyncTimeResponse,
      GetLastRequestedSyncTimeData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.TRANSACTIONS}/last-sync-time`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetLastRequestedSyncTimeResponse) => response,
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useLazyGetAllTransactionsQuery,
  useTriggerTransactionsLambdaMutation,
  useLazyGetLastRequestedSyncTimeQuery,
} = transactionsApi;
