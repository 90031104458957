import { ENDPOINT } from '../config/apiTags';
import { baseApi } from './baseApi.service';

import { GetLeaseBalanceByPeriodData, GetLeaseBalanceByPeriodResponse } from 'src/apiClient';

const balancesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeaseBalanceByPeriod: builder.query<
      GetLeaseBalanceByPeriodResponse,
      GetLeaseBalanceByPeriodData['query']
    >({
      query: (query) => ({
        url: `${ENDPOINT.BALANCES}`,
        method: 'GET',
        params: query,
      }),
      transformResponse: (response: GetLeaseBalanceByPeriodResponse) => response,
    }),
  }),
});

export const { useGetLeaseBalanceByPeriodQuery, useLazyGetLeaseBalanceByPeriodQuery } = balancesApi;
