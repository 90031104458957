import CloudSyncOutlined from '@mui/icons-material/CloudSyncOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { addMinutes, differenceInSeconds, formatDistanceToNow } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserRole } from 'src/apiClient';
import {
  useLazyGetLastRequestedSyncTimeQuery,
  useTriggerTransactionsLambdaMutation,
} from 'src/services/restApi/queries';
import { useUserAuthStore } from 'src/store/userAuth/userStore';

interface RefreshChipProps {
  clientId: string;
  buildingId?: string;
  claimId?: string;
}

const RefreshWrapper = styled(Box)<{ disabled: boolean }>(({ theme, disabled }) => ({
  padding: '4px 4px 4px 18px',
  gap: '6px',
  borderRadius: '100px',
  border: disabled
    ? `2px solid ${theme.palette.action.disabled}`
    : `2px solid ${theme.palette.primary.main}`,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  marginRight: '26px',
  cursor: 'pointer',
}));

const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const RefreshChip: React.FC<RefreshChipProps> = ({ clientId, buildingId, claimId }) => {
  const [getLastSyncTime, { data: lastSyncTime, isFetching }] =
    useLazyGetLastRequestedSyncTimeQuery();
  const [triggerRefresh, { isLoading: isRefreshing }] = useTriggerTransactionsLambdaMutation();
  const [lastSyncDate, setLastSyncDate] = useState<Date | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const dbUser = useUserAuthStore((state) => state.dbUser);

  const bufferTime = claimId ? 2 : buildingId ? 5 : 10; // Buffer times in minutes

  const handleRefreshClick = async () => {
    if (remainingTime && remainingTime > 0) return;

    try {
      await triggerRefresh({
        clientId: Number(clientId),
        buildingId: buildingId ? Number(buildingId) : undefined,
        claimId: claimId ? Number(claimId) : undefined,
      }).unwrap();
      getLastSyncTime({
        clientId: Number(clientId),
        buildingId: buildingId ? Number(buildingId) : undefined,
        claimId: claimId ? Number(claimId) : undefined,
      });
    } catch (error) {
      toast.error('Failed to refresh');
    }
  };

  useEffect(() => {
    // Fetch the last sync time when the component mounts
    getLastSyncTime({
      clientId: Number(clientId),
      buildingId: buildingId ? Number(buildingId) : undefined,
      claimId: claimId ? Number(claimId) : undefined,
    });
  }, [clientId, buildingId, claimId, getLastSyncTime]);

  useEffect(() => {
    if (lastSyncTime?.value?.lastRequestedSyncTime) {
      const lastSync = new Date(lastSyncTime.value.lastRequestedSyncTime);
      setLastSyncDate(lastSync);

      const resetTime = addMinutes(lastSync, bufferTime);
      const timeDiff = differenceInSeconds(resetTime, new Date());

      if (timeDiff > 0) {
        setRemainingTime(timeDiff);
      } else {
        setRemainingTime(null);
      }
    } else {
      setLastSyncDate(null);
      setRemainingTime(null);
    }
  }, [lastSyncTime, bufferTime]);

  // Update the remaining time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingTime !== null && remainingTime > 0) {
        setRemainingTime((prevTime) => (prevTime !== null ? prevTime - 1 : null));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  const getLastSyncLabel = () => {
    if (isFetching) {
      return '-';
    }
    if (!lastSyncDate) {
      return '-'; // Display "-" if there is no last sync time
    }
    return formatDistanceToNow(lastSyncDate, { addSuffix: true });
  };

  const getTooltipContent = () => {
    if (remainingTime === null || remainingTime <= 0) {
      return 'Click to refresh now';
    }
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `Can refresh in ${minutes}m ${seconds}s`;
  };

  return (
    <Tooltip
      title={getTooltipContent()}
      arrow
      disableHoverListener={dbUser?.role.name === UserRole.CLIENT_READ_ONLY}
    >
      <RefreshWrapper
        onClick={dbUser?.role.name !== UserRole.CLIENT_READ_ONLY ? handleRefreshClick : undefined}
        disabled={
          Boolean(remainingTime && remainingTime > 0) ||
          dbUser?.role.name === UserRole.CLIENT_READ_ONLY
        }
        style={{
          cursor:
            (remainingTime && remainingTime > 0) || dbUser?.role.name === UserRole.CLIENT_READ_ONLY
              ? 'not-allowed'
              : 'pointer',
        }}
      >
        <TextWrapper>
          <Typography variant="body2" fontSize="10px">
            Refreshed {claimId ? 'Claim' : buildingId ? 'Building' : 'Client'}
          </Typography>
          <Typography variant="body2" fontSize="10px">
            {isRefreshing ? 'Refreshing...' : getLastSyncLabel()}
          </Typography>
        </TextWrapper>
        <Avatar
          sx={{ bgcolor: remainingTime && remainingTime > 0 ? 'disabled.main' : 'primary.main' }}
        >
          <CloudSyncOutlined fontSize="medium" />
        </Avatar>
      </RefreshWrapper>
    </Tooltip>
  );
};

export default RefreshChip;
